import wathapp from "../../assets/img/whatsapp.png";
import { Link } from "react-router-dom";
import './wa.css'
export const Whatsapp = () => {
  return (
    <div className="wa">
      <Link to="https://wa.link/ttez44">
        <h3 className="waText">Contáctenos al whatsapp</h3>
        <img src={wathapp} alt="wathsapp" />
      </Link>
    </div>
  );
};
