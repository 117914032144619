import "./about.css";

export const AboutContainer = () => {
  return (
    <div id="About">
      <div className="aboutContainer">
        <h2 className="aboutTitle">Te brindamos tu presencia virtual</h2>
        <div className="aboutText">
          <h3>¿Qué es presencia virtual?</h3>
          <p>
            En esta época una de las cosas esenciales es tener presencia virtual
            , es decir que tu cliente pueda conocerte y contactarte por la web ,
            generando mayor confianza y captando mas clientes
          </p>
        </div>
      </div>
    </div>
  );
};
