import "./contacto.css";

export const ContactoContainer = () => {
  return (
    <form
      id="contacto"
      className="container"
      action="https://formsubmit.co/patricio-agustin@hotmail.com"
      method="POST"
    >
      <div className="inner">
        <div className="panel panel-right">
          <div className="panel-content">
            <div className="form">
              <h2>Deja tu consulta</h2>
              <div className="group">
                <input type="text" name="nombre" required />
                <span className="highlight"></span>
                <label>Tu nombre</label>
              </div>
              <div className="group">
                <input type="email" name="email" required />
                <span className="highlight"></span>
                <label>Tu e-mail</label>
              </div>
              <div className="group">
                <textarea type="text" required name="message" />
                <label>Mensaje</label>
              </div>
              <button type="submit" className="send-btn">
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
