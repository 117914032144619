import "./proyectos.css";
import { Carrousel } from "../Carrousel/carrousel";
import landingDeco1 from "../../assets/img/landingDeco.webp";
import landingDeco2 from "../../assets/img/landingDeco.jpg";
import OnepageDeco1 from "../../assets/img/OnepageDeco.webp";
import OnepageDeco2 from "../../assets/img/OnepageDeco.jpg";
import landingPremium1 from "../../assets/img/landingPremium.webp";
import landingPremium2 from "../../assets/img/landingPremium.jpg";

export const ProyectosContainer = ({ precio1, precio2, precio3 }) => {
  return (
    <div id="valores">
      <h2>Nuestros Servicios</h2>
      <div className="container">
        <div className="card">
          <div className="imgBx">
            <picture>
              <source srcset={landingDeco1} type="image/webp" />
              <img loading="lazy" src={landingDeco2} alt="landingPage" />
            </picture>
            <h3>Landing Page</h3>
          </div>
          <div className="content">
            <ul>
              <li>
                El diseño web landing page incluye hasta 10 imagenes en total
              </li>
              <li>
                Hasta 4 paginas incluidas el home, enlaces a todos las redes
                sociales e incluye enlaces externos y Menú.
              </li>
              <li>Hosting gratuito por 1 año</li>
            </ul>
            <span>{precio1}</span>

            <a href="https://landingdecohogar.netlify.app/">
              <div className="btnProyectos">Visitar</div>
            </a>
          </div>
        </div>
        {/* endcard */}
        <div className="card">
          <div className="imgBx">
            <picture>
              <source srcset={OnepageDeco1} type="image/webp" />
              <img loading="lazy" src={OnepageDeco2} alt="OnePage" />
            </picture>
            <h3>OnePage</h3>
          </div>
          <div className="content">
            <ul>
              <li>
                El diseño web landing page incluye hasta 10 imagenes en total
              </li>
              <li>
                Hasta 4 secciones, enlaces a todos las redes sociales e incluye
                enlaces externos y Menú.
              </li>
              <li>Hosting gratuito por 1 año</li>
            </ul>
            <span>{precio2}</span>
            <a href="https://onepagedecohogar.netlify.app/">
              <div className="btnProyectos">Visitar</div>
            </a>
          </div>
        </div>
        {/* endcard */}
        <div className="card">
          <div className="imgBx">
            <picture>
              <source srcset={landingPremium1} type="image/webp" />
              <img loading="lazy" src={landingPremium2} alt="landingPage" />
            </picture>
            <h3>web Premium</h3>
          </div>
          <div className="content">
            <ul>
              <li>
                El diseño web landing page o OnePage premium incluye hasta 20
                imagenes en total
              </li>
              <li>
                {" "}
                6 secciones o paginas incluidas enlaces a todos las redes
                sociales e incluye enlaces externos y menu
              </li>
              <li>Hosting gratuito por 1 año</li>
            </ul>
            <span>{precio3}</span>

            <a href="https://contelasymas.netlify.app/">
              <div className="btnProyectos">Visitar</div>
            </a>
          </div>
        </div>
      </div>
      <Carrousel/>
    </div>
  );
};
