import "./footer.css";

export const Footer = () => {
  return (
    <div className="Footer">
      <h2>PF Web Design </h2>
      <p>Siempre a disposicion del cliente</p>
      <p>Tel :+54 9 1138687263</p>
    </div>
  );
};
