import { NavBar } from './Components/NavBar/NavBar';
import { AboutContainer } from './Components/About/AboutContainer';
import { ProyectosContainer } from './Components/Proyectos/ProyectosContainer';
import { ContactoContainer } from './Components/Contact/ContactoContainer';
import {BrowserRouter} from'react-router-dom';
import { Footer } from './Components/Footer/Footer';
import { Whatsapp } from './Components/whatsapp/Wa';
import './App.css';
import { NosotrosContainer } from './Components/Nosotros/NosotrosContainer';

function App() {
  return (
    <BrowserRouter>  
    <div className="App">
      <NavBar/>
      <AboutContainer/>
      <ProyectosContainer precio1={"Pedi presupuesto"}precio2={"Pedi presupuesto"}precio3={"Pedi presupuesto"}/>
      <NosotrosContainer/> 
      <ContactoContainer/>
      <Whatsapp/>
      <Footer/> 
    </div>
  </BrowserRouter>
  );
}

export default App;
