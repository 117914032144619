import './nosotros.css'


export const NosotrosContainer = () => {
  return (
    <div id="nosotros">
      <div className="nosotrosContent">
        <h3>¿Quienes somos?</h3>
        <div>
          <p>
            Somos PF web design, un equipo de profesionales enamorados de
            nuestro trabajo: brindar soluciones digitales en Internet. Pero
            también forman parte de esta gran familia todos y cada uno de
            nuestros clientes. ¡Si te sumás, nosotros podemos ayudarte!
          </p>
        </div>
      </div>
      <div className="nosotrosContent">
        <h3>¿En qué consiste nuestro trabajo?</h3>
        <div>
          <p>
            Nuestro trabajo consiste en darle a tu negocio su máxima
            rentabilidad haciéndolo visible en el mundo virtual. Para cumplir
            esta tarea, te ofrecemos diseñar o mejorar tu sitio Web,
            posicionarlo de un modo estratégico en Internet. En el fondo
            podríamos resumirlo así: ¡nuestro desafío es hacer que tus
            aspiraciones empresariales se vuelvan realidad!
          </p>
        </div>
      </div>
      <div className="nosotrosContent">
        <h3>¿Cuál es nuestro desafío?</h3>
        <div>
          <p>
            Nuestro desafío consiste en maximizar la competitividad y la
            productividad de tu negocio poniendo al servicio de tus necesidades
            todo nuestro conocimiento, creatividad y experiencia.
          </p>
        </div>
      </div>
    </div>
  );
};
