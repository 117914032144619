import "./carrousel.css";

export const Carrousel = () => {
  return (
    <div className="title">
      <h2>Nuestros Proyectos</h2>
      <div class="contenedor">
        <div>
          <div class="contenido">
            <a href="https://glittering-salamander-557b9a.netlify.app/">
              <h2>Festival de Musica</h2>
              <span>One Page</span>
            </a>
          </div>
        </div>
        <div>
          <div class="contenido">
              <a href="https://proyecto1-paf.netlify.app/">
              <h2>Pagina Servicios</h2>
              <span>One Page</span>
            </a>
          </div>
        </div>
        <div>
          <div class="contenido">
            <a href="https://proyecto3-paf.netlify.app/">
            <h2>Blog Café</h2>
            <span>Landing Page</span>
            </a>
          </div>
        </div>
        <div>
          <div class="contenido">
            <a href="https://proyecto2-paf.netlify.app/">
            <h2>Emprendimiento</h2>
            <span>Landing Page</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
