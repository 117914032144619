import './navBar.css'
import logo from '../../assets/img/logo.jpg'

export const NavBar = () => {
    return (
      <div id="nav">
        <div className="nav_title">
          <img src={logo} alt="logo" />
          <p>PF Web Design </p>
        </div>
        <ul className="nav_list">
          <li>
            <a href="#About">Inicio</a>
          </li>
          <li>
            <a href="#valores">Que ofrecemos</a>
          </li>
          <li>
            <a href="#nosotros">Nosotros</a>
          </li>
          <li>
            <a href="#contacto">Contacto</a>
          </li>
        </ul>
      </div>
    );
  };